import React, { useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import * as classes from './repo.module.scss';

import { DaylightContext } from '../contexts/daylightContext';
import { RepoExtraContext } from '../contexts/repoExtraContext';

import SEO from '../components/SEO';
import BusWidget from '../components/busWidget/BusWidget';
import RiverSection from '../components/riverSection/RiverSection';
import ExternalLink from '../components/svg/external/ExternalLink';

function Repo(props) {
	const slug = props.pageContext.slug;

	const { daylight } = useContext(DaylightContext);
	const { extraRepos } = useContext(RepoExtraContext);

	const [thisRepo, setThisRepo] = useState(null);
	// Filter for the correct repo in extraRepos & set state.
	useEffect(() => {
		if (extraRepos && extraRepos.length) {

			const currentRepo = extraRepos.filter((r) => r.name === slug);
			if (!currentRepo.length) {
				return;
			}
			setThisRepo(currentRepo[0]);
		}
	}, [extraRepos, slug]);

	return (
		<>
			<div className={` ${daylight ? classes.repoWrap : classes.repoWrapDark}`}>
				<main id="main">
					{thisRepo && (
						<>
						<SEO title={thisRepo?.displayName} />
							<div className={classes.markDownIntro}>
								<div className={classes.introText}>
									<h1>{thisRepo.displayName}</h1>
									<a
										href={thisRepo.liveUrl}
										style={{
											textDecorationColor: `${
												daylight ? 'var(--darkBlack)' : 'var(--yellow)'
											}`,
										}}
									>
										<span
											style={{
												color: `${
													daylight ? 'var(--darkBlack)' : 'var(--yellow)'
												}`,
												background: `${
													daylight ? 'var(--yellow)' : 'var(--darkBlack)'
												}`,
											}}
										>
											{thisRepo.displayUrl}
											<ExternalLink />
										</span>
									</a>
									{!thisRepo.localDescription && <p>{thisRepo.description}</p>}
									{thisRepo.localDescription && <thisRepo.localDescription />}
								</div>

								<div className={classes.repoVid}>
									<BusWidget
										style={{ borderRadius: 'var(--default-border-radius)' }}
									/>
								</div>
							</div>
							<div className={classes.box}>
								<div className={classes.markDownWrap}>
									{thisRepo.localReadme && <thisRepo.localReadme />}
									{!thisRepo.localReadme && thisRepo.readmeOld?.text && (
										<ReactMarkdown>{thisRepo.readmeOld.text}</ReactMarkdown>
									)}
									{!thisRepo.localReadme && thisRepo.readmeNew?.text && (
										<ReactMarkdown>{thisRepo.readmeNew.text}</ReactMarkdown>
									)}
									{!thisRepo.localReadme && thisRepo.readmeMain?.text && (
										<ReactMarkdown>{thisRepo.readmeMain.text}</ReactMarkdown>
									)}
									{!thisRepo.localReadme && thisRepo.readmeMainLower?.text && (
										<ReactMarkdown>
											{thisRepo.readmeMainLower.text}
										</ReactMarkdown>
									)}
									{!thisRepo.localReadme && thisRepo.readmeOldLower?.text && (
										<ReactMarkdown>
											{thisRepo.readmeOldLower.text}
										</ReactMarkdown>
									)}
								</div>
							</div>
						</>
					)}
				</main>
			</div>
			<div style={{ overflow: 'hidden' }}>
				<RiverSection sail={true} />
			</div>
		</>
	);
}

export default Repo;
